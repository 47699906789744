<template>
  <div>
    <!-- 顶部面包屑导航 -->
    <breadcrumb-nav>
      <template v-slot:firstMenu>订单管理</template>
      <template v-slot:secondMenu>订单列表</template>
    </breadcrumb-nav>
    <el-card className="box-card">
      <!-- 顶部搜索与添加按钮 -->
      <order-search :listQuery="listQuery" @order-list="updateOrderList"/>
      <!-- 展示的表格 -->
      <order-table :order-list="orderList" :list-query="listQuery" @order-list="updateOrderList"/>
      <!-- 分页 -->
      <order-pagination :list-query="listQuery" :total="total" @page-size-change="handleSizeChange"
                        @current-page-change="handleCurrentChange"/>
    </el-card>
  </div>
</template>

<script>
import BreadcrumbNav from '../common/BreadcrumbNav'
import OrderSearch from './orderChildComponents/OrderSearch'
import OrderTable from './orderChildComponents/OrderTable'
import OrderPagination from './orderChildComponents/OrderPagination'
import { getOrderList } from '../../network/order'

export default {
  name: 'Order',
  components: {
    BreadcrumbNav,
    OrderSearch,
    OrderTable,
    OrderPagination
  },
  data () {
    return {
      listQuery: {
        mobile: '',
        date: '',
        page: 1,
        size: 10,
        status: -1
      },
      orderList: [],
      total: 0
    }
  },
  created () {
    this.getOrderList()
  },
  methods: {
    updateOrderList () {
      this.getOrderList()
    },
    // 监听pageSize改变的事件，并发送新的网络请求
    handleSizeChange (newSize) {
      this.listQuery.size = newSize
      this.getOrderList()
    },

    // 监听页码改变的事件，并发送新的网络请求
    handleCurrentChange (newPage) {
      this.listQuery.page = newPage
      this.getOrderList()
    },
    getOrderList () {
      const query = {
        page: this.listQuery.page,
        size: this.listQuery.size,
        mobile: this.listQuery.mobile,
        status: this.listQuery.status
      }
      if (this.listQuery.date !== null && this.listQuery.date !== undefined && this.listQuery.date.length > 1) {
        query.from = this.listQuery.date[0]
        query.to = this.listQuery.date[1]
      }
      getOrderList(query).then(res => {
        const result = res.data
        if (res.status !== 200) {
          return this.alertMessage('订单列表获取失败', 'error')
        }
        this.orderList = result.data.list
        this.total = result.data.total
      })
    }
  }
}
</script>

<style scoped>

</style>
