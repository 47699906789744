<template>
  <el-table class="custom-order-table" :data="orderList" border stripe>
    <el-table-column prop="orderSn" label="订单编号"></el-table-column>
    <el-table-column prop="mobile" label="用户"></el-table-column>
    <el-table-column prop="orderStatus" label="订单状态">
      <template slot-scope="scope">{{ scope.row.orderStatus | formatOrderStatus }}</template>
    </el-table-column>
    <el-table-column prop="payType" label="支付方式">
      <template slot-scope="scope">{{ scope.row.payType | formatPayType }}</template>
    </el-table-column>
    <el-table-column prop="payStatus" label="支付状态">
      <template slot-scope="scope">{{ scope.row.payStatus | formatPayStatus }}</template>
    </el-table-column>
    <el-table-column prop="actualPrice" label="订单金额" width="120px">
      <template slot-scope="scope">{{ scope.row.actualPrice | floatFormat }}</template>
    </el-table-column>
    <el-table-column prop="refundStatus" label="退货状态" width="120px">
      <template slot-scope="scope">{{ scope.row.refundStatus | formatRefundStatus }}</template>
    </el-table-column>
    <el-table-column prop="refundPrice" label="退款金额" width="120px">
      <template slot-scope="scope">{{ scope.row.refundPrice | floatFormat }}</template>
    </el-table-column>
    <el-table-column prop="createTime" label="创建时间">
      <template slot-scope="scope">{{ scope.row.createTime | dateFormat }}</template>
    </el-table-column>
    <el-table-column label="操作" width="120px">
      <template slot-scope="scope">
        <el-row>
        <el-button type="primary" icon="el-icon-view" size="mini"
                   @click="orderDetail(scope.row.orderId, scope.row.orderSn)">订单详情
        </el-button>
        </el-row>
        <el-row style="margin-top: 10px;" v-show="scope.row.orderStatus == 1">
        <el-button type="success" icon="el-icon-s-finance" size="mini"
                   @click="deliveryOrder(scope.row)">订单发货
        </el-button>
        </el-row>
        <el-row style="margin-top: 10px;" v-show="scope.row.orderStatus === 2 && scope.row.deliverySn === ''">
          <el-button type="success" icon="el-icon-s-finance" size="mini"
                     @click="deliveryOrder(scope.row)">补充物流
          </el-button>
        </el-row>
        <el-row style="margin-top: 10px;" v-show="scope.row.orderStatus === 2 && scope.row.deliverySn !== ''">
          <el-button type="success" icon="el-icon-s-finance" size="mini"
                     @click="deliveryOrder(scope.row)">更改物流
          </el-button>
        </el-row>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
export default {
  name: 'OrderTable',
  props: {
    orderList: {
      type: Array,
      default () {
        return []
      }
    }
  },
  filters: {
    formatOrderStatus (value) {
      switch (value) {
        case 0:
          return '待付款'
        case 1:
          return '待发货'
        case 2:
          return '待收货'
        case 3:
          return '已取消'
        case 4:
          return '交易成功'
        default:
          return '未知'
      }
    },
    formatPayType (value) {
      switch (value) {
        case 1:
          return '支付宝'
        case 2:
          return '微信'
        default:
          return '未支付'
      }
    },
    formatPayStatus (value) {
      switch (value) {
        case 0:
          return '等待支付'
        case 1:
          return '支付失败'
        case 2:
          return '支付成功'
        default:
          return '未知'
      }
    },
    formatRefundStatus (value) {
      switch (value) {
        case 0:
          return '无退款'
        case 1:
          return '部分退款'
        case 2:
          return '全部退款'
        case 3:
          return '退款申请中'
        default:
          return '未知'
      }
    }
  },
  methods: {
    // 监听子组件发射的事件，获得最新的orderList和total信息
    updateOrderList () {
      this.$emit('order-list')
    },
    orderDetail (orderId, orderSn) {
      console.log('orderId:', orderId)
      this.$router.push({
        path: '/detail',
        query: { orderId: orderId, orderSn: orderSn }
      })
    },
    covertOrder (order) {
      const address = order.province + order.city + order.district + order.address
      const listItem = {
        orderId: order.orderId,
        orderSn: order.orderSn,
        consignee: order.consignee,
        mobile: order.mobile,
        address: address,
        deliveryCompany: '',
        deliverySn: ''
      }
      return listItem
    },
    deliveryOrder (row) {
      // const listItem = this.covertOrder(row)
      this.$router.push({
        path: '/delivery',
        query: {
          orderId: row.orderId
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
