<template>
  <el-form :model="listQuery" ref="listQuery" :inline="true" class="demo-form-inline">
    <el-form-item label="用户" prop="mobile">
      <el-input placeholder="下单人手机号" v-model="listQuery.mobile"></el-input>
    </el-form-item>
    <el-form-item label="订单状态" prop="status">
      <el-select v-model="listQuery.status" style="width:100%" @clear="cancelStatus" clearable placeholder="选择订单状态">
        <el-option
          v-for="item in statusOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value">
        </el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="日期范围" prop="date">
      <el-date-picker
        v-model="listQuery.date"
        type="daterange"
        align="right"
        prop="date"
        value-format="timestamp"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
      >
      </el-date-picker>
    </el-form-item>
    <el-form-item>
      <el-button type="primary" @click="search">查询</el-button>
      <el-button @click="resetForm('listQuery')">重置</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
export default {
  name: 'OrderSearch',
  props: {
    listQuery: {
      type: Object,
      default () {
        return {
          mobile: '',
          date: '',
          page: 1, // 当前页
          size: 10 // 每页显示多少条数据
        }
      }
    }
  },
  data () {
    return {
      statusOptions: [
        {
          label: '全部',
          value: -1
        },
        {
          label: '待付款',
          value: 0
        },
        {
          label: '待发货',
          value: 1
        },
        {
          label: '待收货',
          value: 2
        },
        {
          label: '已取消',
          value: 3
        },
        {
          label: '交易成功',
          value: 4
        }
      ]
    }
  },
  methods: {
    // 点击搜索按钮时，通知外部组件重新获取交易列表
    search () {
      this.$emit('order-list')
    },
    resetForm (formName) {
      this.$refs[formName].resetFields()
    },
    cancelStatus () {
      this.listQuery.status = -1
    }
  }
}
</script>

<style scoped>

</style>
